import React from 'react';
import PropTypes from 'prop-types';
import Breach from './Breach';

const Breaches = ({ breaches }) => (
  <section id="breaches" className="section secondary-bg is-medium">
    <div className="columns">
      <div className="column">
        <h3 className="title is-2 text-center">
          Breaches You Were Affected By
        </h3>
      </div>
    </div>
    <div className="columns is-multiline">
      {breaches.map((breach) => (
        <div className="column is-half-desktop">
          <Breach breach={breach} />
        </div>
      ))}
    </div>
    <div className="columns">
      <div className="column">
        <small className="data-disclaimer">
          Data purchased from haveibeenpwned.com (HIBP)
        </small>
      </div>
    </div>
  </section>
);

Breaches.propTypes = {
  // eslint-disable-next-line react/require-default-props
  breaches: PropTypes.arrayOf(PropTypes.object),
};
export default Breaches;
