import React from 'react';
import hacker from '../assets/images/hacker.png';

const Sections = () => (
  <>
    <section className="section is-medium">
      <div className="columns">
        <div className="column">
          <figure className="image">
            <img src={hacker} alt="hacker" />
          </figure>
        </div>
        <div className="column">
          <h3 className="title is-3">Hackers are getting better each year.</h3>
          <div className="content">
            <p>
              Hackers stole nearly 447 million consumer records containing
              sensitive personal information in 2018, according to the
              End-of-Year Data Breach Report from the Identity Theft Resource
              Center.
            </p>
            <p>
              <strong>“The crooks are continuing to get better,”</strong> stated
              Adam Levin, founder and chairman of CyberScout, the data
              protection services firm that backed the file. “Businesses are
              also getting better but, unfortunately, we're in an arms race and
              the bad guys keep advancing faster than the good guys.”
            </p>
            <p>
              Now more than ever, it's important to audit your personal
              security. Start today by using our free service to see if your
              information has been included in a data breach.
            </p>
            <a href="#top" className="button is-primary is-medium">
              Free Data Breach Search
            </a>
          </div>
        </div>
      </div>
    </section>
    <section className="section secondary-bg is-medium">
      <div className="columns">
        <div className="column">
          <h4 className="title is-4">
            There have been over 9,600 reported data breaches since 2008.
          </h4>
          <div className="content">
            <p>
              In addition to the 9,600 reported data breaches in the US between
              2008 and 2019, 10 billion records were stolen during that time
              period. This data indicates that efforts to reduce data theft have
              not been effective.
            </p>
          </div>
        </div>
        <div className="column">
          <h4 className="title is-4">
            In 2019 consumers were hit with $3.5 billion in losses due to fraud.
          </h4>
          <div className="content">
            <p>
              If you are not properly secured against identity theft, the
              financial burden on you and your family could be enormous. In
              2019, consumers faced a bill of 3.9 billion dollars in identity
              theft related expenses.
            </p>
          </div>
        </div>
        <div className="column">
          <h4 className="title is-4">
            Account takeovers up 72 percent from last year.
          </h4>
          <div className="content">
            <p>
              Takeovers occur when a criminal gains access to an account that
              holds personal information. This is usually done by gaining access
              to a consumers email/password combo that is used across a wide
              variety of different services.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="section primary-color-bg is-medium">
      <div className="columns">
        <div className="column">
          <div className="cta-banner">
            <h2 className="title is-2">Has Your Information Been Stolen?</h2>
            <a href="#top" className="button is-large is-outline-button">
              Find Out Today For Free
            </a>
          </div>
        </div>
      </div>
    </section>
  </>
);
export default Sections;
