import React from 'react';
import PropTypes from 'prop-types';

const Breach = ({ breach }) => (
  <div className="card breach">
    <header className="card-header">
      <p className="card-header-title">
        <img src={breach.logo} alt={breach.title} className="breach-logo" />
        {breach.title}
      </p>
    </header>
    <div className="card-content">
      <div className="content">{breach.description}</div>
    </div>
    <footer className="card-footer">
      <div className="field is-grouped is-grouped-multiline">
        <div className="control">
          <div className="tags has-addons">
            <span className="tag is-dark is-medium">Breach Date</span>
            <span className="tag is-primary is-medium">{breach.date}</span>
          </div>
        </div>

        <div className="control">
          <div className="tags has-addons">
            <span className="tag is-dark is-medium">Accounts Stolen</span>
            <span className="tag is-warning is-medium">{breach.affected}</span>
          </div>
        </div>
      </div>
    </footer>
  </div>
);

Breach.propTypes = {
  breach: PropTypes.shape({
    title: PropTypes.string,
    logo: PropTypes.string,
    description: PropTypes.string,
    date: PropTypes.string,
    affected: PropTypes.string,
  }).isRequired,
};

export default Breach;
