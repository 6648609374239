import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Funnel from './pages/Funnel';
import ScrollToTop from './components/ScrollToTop';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import About from './pages/About';

const App = () => (
  <Router>
    <ScrollToTop>
      <Switch>
        <Route path="/" exact>
          <Funnel />
        </Route>
        <Route path="/terms">
          <Terms />
        </Route>
        <Route path="/privacy">
          <Privacy />
        </Route>
        <Route path="/about">
          <About />
        </Route>
      </Switch>
    </ScrollToTop>
  </Router>
);

export default App;
