import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo-white.svg';

const Footer = () => (
  <footer className="info-color-bg">
    <section className="section">
      <div className="columns">
        <div className="column footer-info is-one-quarter">
          <figure className="image">
            <img src={logo} alt="Breach Guard" />
          </figure>
          <p className="is-small">
            Breach Audit was created to offer a free and convenient solution for
            consumers wishing to audit known data breaches for their personal
            information.
          </p>
        </div>
        <div className="column copyright">
          <p>© 2021 www.breachaudit.com - All Rights Reserved.</p>
        </div>
        <div className="column footer-links is-one-quarter">
          <ul>
            <li>
              <Link to="/terms">Terms of Service</Link>
            </li>
            <li>
              <Link to="/privacy">Privacy Policy</Link>
            </li>
            <li>
              <a
                href="https://haveibeenpwned.com/API/v3"
                target="_blank"
                rel="noreferrer"
              >
                Data Provider
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </footer>
);

export default Footer;
