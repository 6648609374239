import he from 'he';

class Breach {
  constructor(Title, LogoPath, Description, BreachDate, PwnCount) {
    this.title = Title;
    this.logo = LogoPath;
    this.description = he.decode(Description.replace(/(<([^>]+)>)/gi, ''));
    this.date = BreachDate;
    this.affected = PwnCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}

export default Breach;
