import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import getLink from '../utils/rotator';
import logo from '../assets/images/logo.svg';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <header>
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <div className="navbar-logo">
            <a href="/">
              <img src={logo} alt="logo" />
            </a>
          </div>

          <button
            onClick={() => {
              setIsMenuOpen(!isMenuOpen);
            }}
            type="button"
            className={`navbar-burger ${isMenuOpen ? 'is-active' : ''}`}
            aria-label="menu"
            aria-expanded="false"
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </button>
        </div>

        <div className={`navbar-menu ${isMenuOpen ? 'is-active' : ''}`}>
          <div className="navbar-start">
            <Link className="navbar-item" to="/about">
              About This Free Service
            </Link>
            <a href={getLink()} className="navbar-item">
              Identify Theft Protection
            </a>
          </div>

          <div className="navbar-end">
            <div className="navbar-item">
              <div className="buttons">
                <a href="/" className="button is-primary">
                  <strong>Free Data Breach Search</strong>
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
