import React from 'react';
import Main from '../layout/Main';

const About = () => (
  <Main>
    <section className="section secondary-bg">
      <div className="columns">
        <div className="column">
          <div className="content">
            <h1>About This Free Service</h1>
            <p>
              This website was was created to offer a free and convenient
              solution for consumers wishing to audit known data breaches for
              their personal information.
            </p>
            <a className="button is-primary is-medium " href="/">
              Search Data Breaches For Free
            </a>
          </div>
        </div>
      </div>
    </section>
  </Main>
);

export default About;
