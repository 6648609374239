import axios from 'axios';
import config from '../config';
import Breach from '../models/Breach';

const headers = {
  version: 'audit',
};

const getRecentBreaches = async () => {
  const breaches = [];
  try {
    const response = await axios.get(`${config.baseUrl}/breaches`, {
      headers,
    });
    return response.data.map(
      (breach) =>
        new Breach(
          breach.Title,
          breach.LogoPath,
          breach.Description,
          breach.BreachDate,
          breach.PwnCount
        )
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
  return breaches;
};

const getBreachesByEmail = async (email) => {
  const results = {
    count: 0,
    names: [],
    data: [],
  };

  try {
    const response = await axios.get(`${config.baseUrl}/breaches/${email}`, {
      headers,
    });
    results.count = response.data.count;
    results.names = response.data.names;
    results.data = response.data.data.map(
      (breach) =>
        new Breach(
          breach.Title,
          breach.LogoPath,
          breach.Description,
          breach.BreachDate,
          breach.PwnCount
        )
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }

  return results;
};

export { getRecentBreaches, getBreachesByEmail };
