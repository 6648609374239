import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import LatestBreaches from '../components/RecentBreaches';
import Sections from '../components/Sections';
import Loader from '../components/Loader';
import { getBreachesByEmail } from '../api/services/breaches';
import Main from '../layout/Main';
import ResultsOverview from '../components/ResultsOverview';
import Results from '../components/Breaches';
import trust from '../assets/images/trust.png';
import idshield from '../assets/images/idshield.png';
import getLink from '../utils/rotator';

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Please enter a valid email address'),
});

const Funnel = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [email, setEmail] = useState();
  const [results, setResults] = useState({});
  const [link] = useState(getLink());
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    // ReactPixel.init('1598378833569144');
    // ReactPixel.pageView();
  }, []);

  const onSubmit = (data) => {
    setIsSubmitting(true);
    setEmail(data.email);
    async function getAndSetBreaches(emailToSearch) {
      const breachesFromApi = await getBreachesByEmail(emailToSearch);
      setResults(breachesFromApi);
      setIsSubmitting(false);
    }
    getAndSetBreaches(data.email);
  };

  const renderLoadingScreen = () => <Loader />;

  const renderResultsScreen = () => (
    <Main>
      <ResultsOverview count={results.count} email={email} link={link} />
      {results.count !== 0 ? (
        <>
          <Results breaches={results.data} />
          <section id="results-cta" className="section is-medium">
            <div className="columns">
              <div className="column">
                <div className="cta-banner">
                  <img
                    style={{ marginBottom: '18px' }}
                    src={idshield}
                    alt="Logo"
                  />
                  <h2 className="title is-2">
                    Protect You Or Your Entire Family with ID Shield
                  </h2>
                  <div className="content">
                    <p>
                      IDShield is the most comprehensive identity theft
                      protection service available. You’ll get best in class
                      service that provides continuous monitoring, includes
                      unlimited recovery of your identity if you are
                      compromised, and educates you about identity theft.
                    </p>
                  </div>
                  <a href={link} className="button is-large is-primary">
                    Protect Yourself Today
                  </a>
                  <img src={trust} alt="trust" />
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        ''
      )}
    </Main>
  );

  if (isSubmitting) {
    return renderLoadingScreen();
  }

  if (email && results) {
    return renderResultsScreen();
  }

  return (
    <Main>
      <div id="top" className="hero-search">
        <div className="container is-v-centered">
          <div className="email-form">
            <h1>Have You Been The Victim of a Data Breach?</h1>
            <h2>
              Hackers steal <strong>millions</strong> of personal records each
              day. Enter your email address below to see if
              <strong> your data</strong> has been compromised.
            </h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <input
                ref={register}
                className={`input is-medium ${errors.email ? 'is-danger' : ''}`}
                type="text"
                placeholder="Email address"
                name="email"
              />
              {errors.email ? (
                <div className="notification is-danger">
                  {errors.email.message}
                </div>
              ) : (
                ''
              )}
              <button type="submit" className="button is-info is-medium">
                Search Data Breaches
              </button>
            </form>
          </div>
        </div>
      </div>
      <Sections />
      <LatestBreaches />
    </Main>
  );
};

export default Funnel;
