import React from 'react';
import PropTypes from 'prop-types';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Main = ({ children }) => (
  <div className="marketing">
    <Header />
    {children}
    <Footer />
  </div>
);

Main.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Main;
