import React from 'react';
import PropTypes from 'prop-types';
import ResultsIcon from './ResultsIcon';

const ResultsOverview = ({ count, email, link }) => {
  if (count !== 0) {
    return (
      <section className="section secondary-bg is-medium results">
        <div className="entry">
          <div className="details">
            <div id="emotion">
              <ResultsIcon type="danger" />
              <h2 className="title is-2">Uh oh.</h2>
            </div>
            <h3 className="title is-3 sans">
              It looks like account information associated with {email} has been
              stolen
              <strong className="is-danger"> {count} times</strong>.
            </h3>
            <div className="content">
              <p>
                Account breaches are no joke. It is recommended that you quickly
                take steps to protect your identity.
              </p>
              <p>
                17.6 million people in the US experience some form of identity
                theft each year. Identity theft can wreck your credit score,
                cost you thousands of dollars and take hundreds of hours of your
                time.
              </p>
              <p>
                While you can't reclaim your data, you can protect yourself from
                identity theft today by enrolling in comprehensive{' '}
                <a href={link}>identity theft protection</a> and{' '}
                <a href={link}>dark web monitoring</a>.
              </p>
            </div>
            <div className="call-to-action">
              <div className="buttons">
                <div>
                  <a href={link} className="button is-success is-large">
                    Protect Yourself Today
                  </a>
                  <small className="hype">For less than $0.39 per day</small>
                </div>
                <a href="#breaches" className="button is-text is-large">
                  Your Breaches
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="section secondary-bg is-medium results">
      <div className="entry">
        <div className="details">
          <div id="emotion">
            <ResultsIcon />
            <h2 className="title is-2">Woah!</h2>
          </div>
          <h3 className="title is-3 sans">
            It doesn't look like that email address has been reported in any
            known breaches.
          </h3>
          <div className="content">
            <p>
              Account breaches are no joke. And just because we don't have
              record of a breach associated with that email, your data is not
              necessarily safe.
            </p>
            <p>
              Hackers stole nearly 447 million consumer records containing
              sensitive personal information in 2018. Unfortunately, it may just
              be a matter of time before you are affected.
            </p>
            <p>
              17.6 million people in the US experience some form of identity
              theft each year. Identity theft can wreck your credit score, cost
              you thousands of dollars and take hundreds of hours of your time.
            </p>
            <p>
              The best way to protect yourself from identity theft is by
              enrolling in comprehensive{' '}
              <a href={link}>identity theft protection</a> and{' '}
              <a href="https://juhlbrian.wearelegalshield.com/ids">
                dark web monitoring
              </a>
              .
            </p>
          </div>
          <div className="call-to-action">
            <div className="buttons">
              <a href={link} className="button is-success is-large">
                Protect Yourself Today
              </a>
            </div>
            <small className="hype">For less than $0.39 per day</small>
          </div>
        </div>
      </div>
    </section>
  );
};

ResultsOverview.propTypes = {
  count: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default ResultsOverview;
