import React from 'react';
import { css } from '@emotion/react';
import ClipLoader from 'react-spinners/ClipLoader';

const override = css`
  display: block;
  margin: 0 auto;
`;

const Loader = () => (
  <div className="loader-container">
    <ClipLoader color="#4274B1" loading css={override} size={100} />
  </div>
);

export default Loader;
