import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faGlassCheers,
} from '@fortawesome/free-solid-svg-icons';

const ResultsIcon = ({ type }) => {
  let Icon;
  switch (type) {
    case 'danger':
      Icon = (
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          color="#c7392e"
          size="4x"
        />
      );
      break;
    default:
      Icon = <FontAwesomeIcon icon={faGlassCheers} color="#48c774" size="4x" />;
  }

  return Icon;
};

ResultsIcon.propTypes = {
  type: PropTypes.string,
};

ResultsIcon.defaultProps = {
  type: '',
};

export default ResultsIcon;
