import React, { useState, useEffect } from 'react';
import { getRecentBreaches } from '../api/services/breaches';
import Breach from './Breach';
import Loader from './Loader';

const RecentBreaches = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [breaches, setBreaches] = useState([]);
  useEffect(() => {
    async function getAndSetBreaches() {
      const breachesFromApi = await getRecentBreaches();
      setBreaches(breachesFromApi);
      setIsLoading(false);
    }
    getAndSetBreaches();
  }, []);

  return (
    <section id="breaches" className="section is-medium">
      <div className="columns">
        <div className="column">
          <h3 className="title is-2 text-center">Recent Data Breaches</h3>
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="columns">
          <div className="column is-half-desktop">
            {breaches.slice(0, 3).map((breach) => (
              <Breach key={breach.title} breach={breach} />
            ))}
          </div>
          <div className="column is-half-desktop">
            {breaches.slice(3, 6).map((breach) => (
              <Breach key={breach.title} breach={breach} />
            ))}
            <small className="data-disclaimer">
              Data purchased from haveibeenpwned.com (HIBP)
            </small>
          </div>
        </div>
      )}
    </section>
  );
};

export default RecentBreaches;
